<template>
  <div class="container" v-title :data-title="$t('i18n.nodeLibraryDeploy')">
    <div id="outer-title">{{ $t("i18n.nodeLibraryDeploy") }}</div>
    <p class="fl failTxt" v-if="selectRow">已选择：{{ selectRow.depotName }}</p>
    <p class="fl failTxt" v-else-if="tableData">{{ $t("i18n.selectTips") }}</p>
    <div v-loading="loading">
      <jl-table
        :tableData="tableData"
        @init="init"
        :options="options"
        :columns="columns"
        :operates="operates"
        :total="total"
        :height="$defined.HEIGHT - 360 + 'px'"
      >
      </jl-table>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import jlTable from "../../components/table";
export default {
  name: "Config",
  components: {
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const router = useRouter();
    const state = reactive({
      formInline: {
        pageNumber: 1,
        pageSize: 10,
      },
      // table数据
      tableData: [],
      options: {
        // table 的参数
        isAdd: false, // 是否启用新增功能
        isEdit: false, // 是否启用编辑功能
        isDelete: false, // 是否启用删除功能
        highlightCurrentRow: true, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      columns: [
        {
          align: "center",
          formatter: (row) => {
            if (row.id === state.selectRow.id) {
              row.select = true;
              return `<img src="${require("../../assets/xuanzhong.png")}" />`;
            } else {
              return `<img src="${require("../../assets/weixuanzhong.png")}" />`;
            }
          },
          method: (row, formInline) => {
            setSelectDepot(row, formInline);
          },
        },
        {
          prop: "depotName",
          label: "name",
          align: "left",
        },
        {
          prop: "companyName",
          label: "companyName",
          align: "left",
        },
      ],
      operates: {
        // 列操作按钮
        width: 80,
        fixed: "right",
        list: [
          {
            id: "1",
            label: "view",
            icon: "el-icon-view",
            show: true,
            plain: false,
            disabled: false,
            type: "inner",
            method: (index, row) => {
              router.push({
                path: "/install/opertionNodeConfig",
                query: { depotId: row.id, view: true },
              });
            },
          },
        ],
      },
      total: 0,
      loading: true,
      editData: {},
      selectRow: "",
    });

    const init = async (params) => {
      state.loading = true;
      const { data } = await proxy.$api.system.installProjectDepot(params);
      state.tableData = data.records;
      state.total = data.total;
      state.loading = false;
      getSelectDepot();
    };

    const getSelectDepot = async () => {
      const { data } = await proxy.$api.system.getSelectDepot();
      state.selectRow = data;
    };

    const setSelectDepot = (row, formInline) => {
      proxy.$defined.confirm(
        () => {
          proxy.$api.system.setSelectDepot(row.id).then(() => {
            proxy.$defined.tip(t("i18n.setUp") + t("i18n.success"), "success");
            init(formInline);
          });
        },
        t("i18n.setUp"),
        t("i18n.nodeLibraryDeploy")
      );
    };

    return {
      ...toRefs(state),
      init,
      setSelectDepot,
    };
  },
};
</script>
